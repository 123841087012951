import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';


const SongPage = () => {
  let { uid } = useParams();
  const [broadcaster, setBroadcaster] = useState(null);
  const [songs, setSongs] = useState([]);
  const [originalSongs, setOriginalSongs] = useState([]); // 存储最初的歌曲列表
  const [searchTerm, setSearchTerm] = useState(''); // 搜索框的状态

  useEffect(() => {
    fetch(`https://api.vup.name/api/v1/songlist?uid=${uid}`)
      .then((response) => response.json())
      .then((data) => {
        setBroadcaster(data.broadcaster);
        setSongs(data.songs);
        setOriginalSongs(data.songs); // 存储最初的歌曲列表
        if (data.broadcaster && data.broadcaster.name) {
          document.title = data.broadcaster.name; // 设置文档标题
        }
      });
  }, [uid]);

  // 搜索框变化的处理函数
  const handleSearchChange = (event) => {
    setSearchTerm(event.target.value);
  };

  useEffect(() => {
    if (searchTerm.trim() === '') {
      setSongs(originalSongs); // 显示原始歌曲列表
      return;
    }

    // 发起搜索请求
    fetch(`https://api.vup.name/api/v1/search?uid=${uid}&q=${encodeURIComponent(searchTerm)}`)
      .then(response => response.json())
      .then(data => {
        setSongs(data); // 使用搜索结果更新歌曲列表
      })
      .catch(error => {
        console.error('Error fetching search results:', error);
      });
  }, [searchTerm, uid, originalSongs]);

  const [showPopup, setShowPopup] = useState(false);
  const [popupMessage, setPopupMessage] = useState('');
  const handleRowClick = (songTitle) => {
    navigator.clipboard.writeText(`点歌 ${songTitle}`).then(() => {
      setPopupMessage(`点歌 ${songTitle}`); // 设置弹窗消息
      setShowPopup(true); // 显示弹窗
  
      // 三秒后关闭弹窗
      setTimeout(() => {
        setShowPopup(false);
      }, 3000);
    });
  };
  
  const tableStyle = {
    borderRadius: '10px',
    backdropFilter: 'blur(20px)',
    padding: '1rem',
    maxWidth: '1320px',
    boxShadow: 'rgb(0 0 0 / 0%) 0px 0px 20px 20px inset, rgba(0, 0, 0, 0.15) 0px 0.125rem 2rem',
    margin: '2rem 0 2rem',
    fontSize: '15px',
    fontWeight: '700',
    marginRight: 'auto',
    marginLeft: 'auto',
    backgroundColor: 'rgb(0 0 0 / 19%)',
    color: '#fff'
  };

  const tabletrStyle = {
    height: '50px',
    fontSize: '20px',
    color:'#fff'
    
  };
  const bgStyle = {
    backgroundImage: broadcaster ? `url(${broadcaster.background_image_url})` : '',
    backdropFilter: 'blur(10px)',
    padding: '20px',
    width: '100%',
    height: '100%',
    margin: '0',
    backgroundRepeat: 'no-repeat',
    backgroundSize: 'cover',
    backgroundPosition: 'center',
    backgroundAttachment: 'fixed' // 这里添加了固定背景图的属性
  };
  
  const bannerStyle = {
    boxSizing: 'border-box',
    backdropFilter: 'blur(200px)',
    padding: '20px',
    maxWidth: '1320px',
    width: '100%', // Set width to 100% or another fixed value if you want to center the element
    height: 'auto', // You can adjust the height as needed
    marginRight: 'auto',
    marginLeft: 'auto',
    backgroundRepeat: 'no-repeat',
    backgroundSize: 'cover',
    backgroundPosition: 'center',
  };
  const popupStyle = {
    backgroundColor: '#FFFFFF50',
    backdropFilter: 'blur(20px)',
  };
  const searchStyle = {
    backgroundColor: '#00000010',
    backdropFilter: 'blur(20px)',
    borderWidth: '0px',
    color: '#fff',
  };
  const restStyle = {
    backgroundColor: '#00000010',
    backdropFilter: 'blur(10px)',
    borderWidth: '0px',
    color: '#acb1b9',
    marginLeft: '15px',
  };
  


  return (
    <><div style={bgStyle}><link href="https://cdn.jsdelivr.net/npm/daisyui@3.9.4/dist/full.css" rel="stylesheet" type="text/css" /><script src="https://cdn.tailwindcss.com"></script><div className="p-4">
      {broadcaster && (
        <div className="card lg:card-side shadow-xl" style={bannerStyle}>
          <div class="avatar" style={{ height: '120px' }}>
            <div class="w-24 mask mask-squircle">
              <img src={broadcaster.profile_url} />
            </div>
          </div>
          <div className="card-body">
            <h2 className="card-title" style={{ textShadow: '0 0 3px BLACK', color:'#FFF' }}>{broadcaster.name}</h2>
            <p style={{ textShadow: '0 0 3px BLACK', color:'#FFF' }}>{broadcaster.description}</p>
          </div>
        </div>
      )}
      <div style={{ maxWidth: '1320px', margin: '1rem auto' }}>
       {/* 搜索框和重置按钮 */}
<div style={{ maxWidth: '1320px', margin: '1rem auto', display: 'flex', alignItems: 'center' }}>
  <input type="text" className="input input-bordered w-full" placeholder="搜索歌曲..." value={searchTerm} onChange={handleSearchChange} style={searchStyle} />
  <button className="btn" onClick={() => window.location.reload()} style={restStyle}>
    重置
  </button>
</div>
</div>

      <div className="overflow-x-auto mt-4">
        <table className="table w-full" style={tableStyle}>
          <thead>
            <tr>
              <th style={tabletrStyle} >歌曲名</th>
              <th style={tabletrStyle} >作者</th>
              <th style={tabletrStyle} >语言</th>
              <th style={tabletrStyle} >是否付费</th>
            </tr>
          </thead>
          <tbody>
            {songs.map((song, index) => (
              <tr key={index} className="hover:background-color:#000;" onClick={() => handleRowClick(song.title)}>
                <td>{song.title}</td>
                <td>{song.artist}</td>
                <td>{song.language}</td>
                <td>{song.is_paid ? '是' : '否'}</td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
    </div>
  </div> {showPopup && (
    <div className="toast toast-top toast-start">
      <div className="alert alert-success" style={popupStyle}>
        {popupMessage}
      </div></div>
    )}</>
  );
};

export default SongPage;
