import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faList } from '@fortawesome/free-solid-svg-icons';
import { faGuitar } from '@fortawesome/free-solid-svg-icons';
import { faBilibili } from '@fortawesome/free-brands-svg-icons';
import { faBoxOpen } from '@fortawesome/free-solid-svg-icons';

const Info = () => {
  let { uid } = useParams();
  const [broadcaster, setBroadcaster] = useState(null);
  const [songs, setSongs] = useState([]);
  const [originalSongs, setOriginalSongs] = useState([]); // 存储最初的歌曲列表


  useEffect(() => {
    fetch(`https://api.vup.name/api/v1/songlist?uid=${uid}`)
      .then((response) => response.json())
      .then((data) => {
        setBroadcaster(data.broadcaster);
        setSongs(data.songs);
        setOriginalSongs(data.songs); // 存储最初的歌曲列表
        if (data.broadcaster && data.broadcaster.name) {
          document.title = data.broadcaster.name; // 设置文档标题
        }
      });
  }, [uid]);

  const bgStyle = {
    backgroundImage: broadcaster ? `url(${broadcaster.background_image_url})` : '',
    backdropFilter: 'blur(10px)',
    padding: '20px',
    width: '100%',
    height: '100%',
    margin: '0',
    backgroundRepeat: 'no-repeat',
    backgroundSize: 'cover',
    backgroundPosition: 'center',
    backgroundAttachment: 'fixed' // 这里添加了固定背景图的属性
  };
  const bannerStyle = {
    boxSizing: 'border-box',
    backdropFilter: 'blur(200px)',
    padding: '20px',
    borderRadius: '20px',
    maxWidth: '1320px',
    width: '100%', // Set width to 100% or another fixed value if you want to center the element
    height: 'auto', // You can adjust the height as needed
    marginRight: 'auto',
    marginLeft: 'auto',
    backgroundRepeat: 'no-repeat',
    backgroundSize: 'cover',
    backgroundPosition: 'center',
  };
  const containerStyle = {
    display: 'flex',         // 启用 Flexbox
    justifyContent: 'center', // 水平居中
    alignItems: 'center',     // 垂直居中
    height: '100vh',          // 容器高度设置为视口的100%
    width: '100%'            // 容器宽度设置为视口的100%
  };
  const Card = ({ text, link }) => {
    return (
      <a href={link} className="card hover:bg-gray-100 p-4 flex flex-col items-center justify-center">
        <FontAwesomeIcon icon={faList} size="2x" />
        <p>{text}</p>
      </a>
    );
  };

  return (
    <><div style={bgStyle}><div style={containerStyle}><link href="https://cdn.jsdelivr.net/npm/daisyui@3.9.4/dist/full.css" rel="stylesheet" type="text/css" /><script src="https://cdn.tailwindcss.com"></script><div className="p-4">
      {broadcaster && (
        <div className="card lg:card-side shadow-xl" style={bannerStyle}>
          <div className="avatar" style={{ height: '120px' }}>
            <div className="w-24 mask mask-squircle">
              <img src={broadcaster.profile_url} />
            </div>
          </div>
          <div className="card-body">
            <h2 className="card-title" style={{ textShadow: '0 0 3px BLACK', color:'#FFF' }}>{broadcaster.name}</h2>
            <p style={{ textShadow: '0 0 3px BLACK', color:'#FFF' }}>{broadcaster.description}</p>
          </div>
        </div>
      )}
      <div className="container">
      <a href={`/#/${uid}/song`} className="column">
          <div className="column">
          <FontAwesomeIcon icon={faGuitar} bounce size="2xl" style={{color: "#ffffff",}} /><br></br>歌单
          </div> </a><a href={`https://space.bilibili.com/${uid}`} className="column">
          <div className="column">
          <FontAwesomeIcon icon={faBilibili} bounce size="2xl" style={{color: "#ffffff",}} /> <br></br>BiliBili主页 
          </div></a><a href="#" className="column">
          <div className="column">
          <FontAwesomeIcon icon={faBoxOpen} bounce size="2xl" style={{color: "#ffffff",}} /> <br></br>提问箱
          </div></a>
        </div>
    </div>
    </div>
  </div> </>
  );
};

export default Info;
