import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faHome, faComments } from '@fortawesome/free-solid-svg-icons';

function HomePage() {
  const [vList, setVList] = useState([]);
  const [loading, setLoading] = useState(false);
  const [broadcaster, setBroadcaster] = useState(null);

  useEffect(() => {
    document.title = "八木歌单";
    const fetchData = async () => {
      setLoading(true);
      try {
        const response = await fetch('https://api.vup.name/api/v1/vlist');
        if (!response.ok) {
          throw new Error('Network response was not ok');
        }
        const data = await response.json();
        setVList(data);
      } catch (error) {
        console.error('Fetch error:', error);
      } finally {
        setLoading(false);
      }
    };

    fetchData();
  }, []);
  const bgStyle = {
    backgroundColor: '#f5f5f5', // 浅灰色背景
    padding: '20px',
    width: '100%',
    height: '100vh', // 设置高度为视口高度的 100%
    margin: '0',
    backgroundRepeat: 'no-repeat',
    backgroundSize: 'cover',
    backgroundPosition: 'center',
    backgroundAttachment: 'fixed',
    backdropFilter: 'blur(10px)',
  };
  const containerStyle = {
    margin: '0 auto',
    maxWidth: '1300px',
    display: 'grid',
    gridTemplateColumns: 'repeat(1, 1fr)', // 确保三列布局
    gap: '16px' // 您可以根据需要调整间隙大小
  };
  const borderRadius = {
    borderRadius: '10px',
    marginBottom: '20px',
  };
  const cardStyle = {
    height: '300px', // 或您期望的高度
    borderRadius: '15px',
    marginBottom: '20px',
    backgroundSize: 'cover',
    backgroundPosition: 'center'
    // 移除宽度设置，因为网格布局会自动处理
  };
  const imgStyle = {
    backgroundSize: 'cover',
    backgroundPosition: 'center'
    // 移除宽度设置，因为网格布局会自动处理
  };
  

  if (loading) {
    return <div>Loading...</div>;
  }

  return (
    <><div style={bgStyle}>
      <link href="https://cdn.jsdelivr.net/npm/daisyui@3.9.4/dist/full.css" rel="stylesheet" type="text/css" />
      <script src="https://cdn.tailwindcss.com"></script>
      <script src="https://kit.fontawesome.com/your-fontawesome-kit-code.js"></script>
      <div className="navbar bg-base-100" style={borderRadius}>
        
  <div className="navbar-start">
    <div className="dropdown">
      
    </div>
    <a className="btn btn-ghost text-xl">歌单</a>
  </div>
  <div className="navbar-center hidden lg:flex">
    <ul className="menu menu-horizontal px-1">
      <li><Link to="/" className="flex items-center">
          <FontAwesomeIcon icon={faHome} className="mr-2" /> 首页
        </Link></li>
      <li tabIndex={0}>
      </li>
    </ul>
  </div>

</div><div style={containerStyle}>
<div className="alert alert-error">
  <svg xmlns="http://www.w3.org/2000/svg" className="stroke-current shrink-0 h-6 w-6" fill="none" viewBox="0 0 24 24"><path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M10 14l2-2m0 0l2-2m-2 2l-2-2m2 2l2 2m7-2a9 9 0 11-18 0 9 9 0 0118 0z" /></svg>
  <span>持续开发中，更多功能，敬请期待！</span>
</div>
<div className="grid grid-cols-3 gap-4 p-4">
        {vList.map((item) => (
          <Link to={`/${item.uid}`} key={item.uid} className="card bg-base-100 shadow-xl image-full" style={cardStyle}>
            <figure className="px-10 pt-10">
              <img src={item.background_image_url} alt={item.name} style={imgStyle}/>
            </figure>
            <div className="card-body  items-center text-center">
              <h2 className="card-title">{item.name}</h2>
              <p>{item.description}</p>
            </div>
          </Link>
        ))}
      </div>
    </div>
  </div>
    </>
  );
}

export default HomePage;
